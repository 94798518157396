<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        History Registrasi <br />

        <v-spacer></v-spacer>

        <!-- <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadInvoice()"
        >
          Download
        </v-btn> -->
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.nama"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.invoice_no"
            append-icon="mdi-magnify"
            label="No Invoice"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Kategori"
            clearable
            dense
            outlined
            v-model="search.selectedKategori"
            :items="items_kategori"
            item-text="name"
            @change="getDataByKategori"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4"></v-col>
        <v-col cols="12" sm="4" md="4"> </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Item"
            clearable
            dense
            outlined
            v-model="search.selectedItem"
            :items="items_item"
            item-text="nama"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal_hadir`]="{ item }">
          {{ getCurrentDate(item.tanggal_hadir) }}
        </template>
        <template v-slot:[`item.status_registrasi`]="{ item }">
          <v-chip
            v-if="item.status_registrasi == 1"
            :color="getColor(item.status_registrasi)"
            dark
          >
            {{ getStatus(item.status_registrasi) }}
          </v-chip>
        </template>
        <template v-slot:[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <!-- <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon> -->
            <v-icon medium class="mr-3" @click="printCard(item)">
              mdi-printer
            </v-icon>
            <v-icon
              medium
              class="mr-3"
              @click="printCardNama(item.nama_ktp ?? item.user_nama)"
            >
              mdi-printer
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <InvoiceItemPibComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>

    <!-- Modal -->

    <v-dialog v-model="dialogUpdateIdentitas" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Peserta</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="6" class="pr-4">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoKey"
                        :src="currentData.FotoUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImageProfile"
                    accept="image/*"
                    v-model="currentData.file_foto"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Status Profesi"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKategori"
                        :items="items_profesi"
                        item-text="value"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      currentData.selectedKategori &&
                      currentData.selectedKategori.code == 1
                    "
                    dense
                  >
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Cabang"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedPropinsi"
                        :items="items_propinsi"
                        item-text="name"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      currentData.selectedKategori &&
                      currentData.selectedKategori.code == 1
                    "
                    dense
                  >
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="No NPA"
                        outlined
                        dense
                        v-model="currentData.no_npa"
                        required
                        :rules="currentData.noNpaRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Domisili Propinsi"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedDomisiliPropinsi"
                        :items="items_propinsi"
                        item-text="name"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nama & Gelar"
                        outlined
                        dense
                        v-model="currentData.nama"
                        required
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        label="No Telepon/HP"
                        outlined
                        dense
                        v-model="currentData.whatsapp"
                        required
                        :rules="currentData.whatsappRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        label="Email"
                        outlined
                        dense
                        v-model="currentData.email"
                        required
                        :rules="currentData.emailRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Institusi"
                        outlined
                        dense
                        v-model="currentData.institusi"
                        required
                        :rules="currentData.institusiRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="text-h5 mx-2">Data Sponsor</v-row>
                  <v-row dense class="mt-6">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nama Sponsor"
                        outlined
                        dense
                        v-model="currentData.nama_sponsor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-4">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Alamat Sponsor"
                        outlined
                        dense
                        v-model="currentData.alamat_sponsor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-4">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nama Kontak Person Sponsor"
                        outlined
                        dense
                        v-model="currentData.nama_kontak_person_sponsor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-4">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Handphone Kontak Person Sponsor"
                        outlined
                        dense
                        v-model="currentData.hp_kontak_person_sponsor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-4">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Email Sponsor"
                        outlined
                        dense
                        v-model="currentData.email_sponsor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-4">
                    <v-col cols="12" sm="12" md="12">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoBuktiUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadBuktiImage"
                        accept="image/*"
                        v-model="currentData.file_bukti_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Bukti Transfer / Surat Garansi Sponsor"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogUpdateIdentitas = false"
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPrintNama" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Print Nama</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formCatatan"
            v-model="valid"
            lazy-validation
            class="pl-4 pr-4"
          >
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="currentNama"
                    required
                    :rules="currentData.namaRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPrintNama = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="doPrintCardNama">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InasiaKehadiranService from "../../services/inasia/InasiaKehadiranService"
import * as moment from "moment"
import InvoiceItemPibComponent from "./InasiaInvoiceItem.vue"
import InasiaInvoiceService from "../../services/inasia/InasiaInvoiceService"
import InasiaPesertaService from "../../services/inasia/InasiaPesertaService"
import MasterService from "../../services/MasterService"
import NakesService from "../../services/NakesService"
import CommonService from "../../services/CommonService"
import print from "print-js"

export default {
  props: {
    id: String,
  },
  components: { InvoiceItemPibComponent },
  data: () => ({
    isProgressBarShow: false,
    childKey: 0,
    isEditable: 0,
    fotoKey: 0,
    fotoKeyProfile: 0,
    valid: true,
    dialogUpdateIdentitas: false,
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Nama KTP", value: "nama_ktp", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Tanggal Hadir", value: "tanggal_hadir", sortable: false },
      { text: "Profesi", value: "profesi_pib", sortable: false },
      // { text: "Catatan", value: "catatan", sortable: false },
      {
        text: "Domisili Propinsi",
        value: "domisili_propinsi_nama",
        sortable: false,
      },

      // { text: "Status", value: "status_registrasi", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    search: {
      nama: "",
      tahun: "",
      invoice_no: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
      selectedSponsor: "",
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      FotoBuktiUrl: "",
      file_foto: null,
      nama: "",
      title: "",
      email: "",
      whatsapp: "",
      selectedKategori: "",
      selectedPropinsi: "",
      selectedDomisiliPropinsi: "",
      nama_sponsor: "",
      alamat_sponsor: "",
      nama_kontak_person_sponsor: "",
      hp_kontak_person_sponsor: "",
      email_sponsor: "",
      file_bukti_foto: null,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      titleRules: [(v) => !!v || "Title harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      whatsappRules: [(v) => !!v || "Whatsapp harus diisi"],
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
    ],
    items_item: [],
    items_profesi: [],
    items_propinsi: [],

    currentNama: "",
    dialogPrintNama: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListStatusProfesi()
    this.getListPropinsi()
    this.getDataFromApi()
  },
  methods: {
    async getListStatusProfesi() {
      MasterService.getStatusProfesi()
        .then((res) => {
          this.items_profesi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getColor(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "blue"
      }
    },

    getDataByKategori() {
      this.getDataFromApi()

      if (this.search.selectedKategori)
        this.getKategoriItem(this.search.selectedKategori.id)
      else this.getKategoriItem("")

      this.search.selectedItem = null
    },

    getStatus(id) {
      if (id == 1) {
        return "Registrasi Berhasil"
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY HH:mm")
    },

    getKategoriItem(kategori) {
      InasiaInvoiceService.getKategoriItemByInasia(this.id, kategori)
        .then((res) => {
          this.items_item = res.data.list_data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true

      var status = ""
      var kategori = ""
      var item = ""
      var sponsor = ""

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id
      }

      if (this.search.selectedSponsor) {
        sponsor = this.search.selectedSponsor.id
      }

      this.loading = true
      InasiaKehadiranService.kehadiranList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.invoice_no,
        status,
        kategori,
        item,
        sponsor,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    editItem(item) {
      console.log(item.id)
      this.currentInvoiceNo = item.invoice_no
      this.getPesertaPib(item)
    },

    async getPesertaPib(item) {
      InasiaPesertaService.pesertaPib(item.user_id)
        .then((res) => {
          var myuser = res.data.user

          this.currentData.id = myuser.id
          this.currentData.pib_id = myuser.pib_id
          this.currentData.nama = myuser.nama
          this.currentData.whatsapp = myuser.whatsapp
          this.currentData.email = myuser.email
          this.currentData.institusi = myuser.institusi
          this.currentData.no_npa = myuser.no_npa
          this.currentData.nama_sponsor = myuser.nama_sponsor
          this.currentData.alamat_sponsor = myuser.alamat_sponsor
          this.currentData.nama_kontak_person_sponsor =
            myuser.nama_kontak_person_sponsor
          this.currentData.hp_kontak_person_sponsor =
            myuser.hp_kontak_person_sponsor
          this.currentData.email_sponsor = myuser.email_sponsor

          this.currentData.FotoUrl = myuser.photo
          this.currentData.FotoBuktiUrl = myuser.bukti_transfer_sponsor

          if (myuser.jenis_anggota) {
            this.items_profesi.forEach((entry) => {
              if (entry.code == myuser.jenis_anggota) {
                this.currentData.selectedKategori = entry
              }
            })
          }

          if (myuser.cabang_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == myuser.cabang_id) {
                this.currentData.selectedPropinsi = entry
              }
            })
          }

          if (myuser.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == myuser.propinsi_id) {
                this.currentData.selectedDomisiliPropinsi = entry
              }
            })
          }

          this.dialogUpdateIdentitas = true
        })
        .catch((err) => {
          console.log(err)
        })
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.currentData.pib_id = this.id

      InasiaPesertaService.update(this.currentData)
        .then((res) => {
          console.log(res)

          CommonService.showSuccessToast("Update Peserta berhasil")

          this.dialogUpdateIdentitas = false

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)

          CommonService.showErrorToast("Update Peserta gagal. Coba lagi")
        })
    },

    async printCard(item) {
      console.log(item.id)
      // print("printJS-form", "html");
      InasiaPesertaService.pesertaCard(item.user_id)
        .then((res) => {
          console.log(res.data.link)
          print({
            printable: [res.data.link],
            type: "image",
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async printCardNama(nama) {
      this.currentNama = nama
      this.dialogPrintNama = true
    },

    async doPrintCardNama() {
      // print("printJS-form", "html");
      InasiaPesertaService.pesertaCardNama(this.currentNama)
        .then((res) => {
          this.dialogPrintNama = false
          console.log(res.data.link)
          print({
            printable: [res.data.link],
            type: "image",
            maxWidth: 227,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    loadBuktiImage: function (data) {
      console.log("loadImage:", data)
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.currentData.FotoBuktiUrl = e.target.result
          this.fotoKey++
        }
        reader.readAsDataURL(data)
      } else {
        this.currentData.FotoBuktiUrl = ""
        this.currentData.file_bukti_foto = null
      }
    },

    loadImageProfile: function (data) {
      console.log("loadImage:", data)
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result
          this.fotoKeyProfile++
        }
        reader.readAsDataURL(data)
      } else {
        this.currentData.FotoUrl = ""
        this.currentData.file_foto = null
      }
    },

    onDownloadInvoice() {
      this.isProgressBarShow = true

      var kategori = ""
      var item = ""

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id
      }

      InasiaKehadiranService.downloadKehadiranReport(
        this.search.nama,
        this.search.invoice_no,
        kategori,
        item
      )
        .then((res) => {
          window.open(res.data.file, "_blank")
          this.isProgressBarShow = false
        })
        .catch((err) => {
          this.isProgressBarShow = false
          CommonService.showErrorToast("Gagal download report. Coba lagi")
          console.log(err)
        })
    },
  },
}
</script>
