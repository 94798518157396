<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-form v-on:submit.prevent="submitForm">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Perdatin Admin</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="user.email"
                    label="Email / NPA"
                    autocomplete
                    name="email"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    label="Password"
                    autocomplete
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" style="color: white" type="submit"
                    >Login</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import store from "../store/index"

export default {
  name: "Login",
  data: () => ({
    user: {
      email: "",
      password: "",
    },
  }),
  methods: {
    submitForm: async function () {
      this.$store
        .dispatch("login", this.user)
        .then(() => {
          if (this.getUser.admin_kppia_additional == 1) {
            this.$router.push("/inasia-registrasi-ulang?id=2")
          } else {
            this.$router.push("/nakes")
          }

          this.$router.go()
        })
        .catch((err) => {
          alert("terjadi kesalahan. coba lagi")
          console.log(err)
        })
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id
    },
    getIsVerified() {
      return this.isVerified
    },
    getUser() {
      return store.getters.getUser
    },
  },
}
</script>

<style scoped></style>
