<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <VRow class="px-8 pt-4 items-center">
        <p class="text-h6">Peserta</p>
        <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="ml-2" @click="openCopy">
          Copy Peserta
        </v-btn>

        <v-btn color="primary" dark class="ml-2" @click="openTambah">
          Tambah
        </v-btn>

        <v-btn color="primary" dark class="ml-2" @click="openDeleteAll">
          Hapus Peserta
        </v-btn>
      </VRow>

      <VRow class="px-8 pt-2 items-center">
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="ml-2" @click="openUpload">
          Upload Peserta
        </v-btn>

        <v-btn color="primary" dark class="ml-2" @click="downloadSample">
          Download Sample Upload
        </v-btn>
      </VRow>

      <v-card-title>
        <v-dialog v-model="dialog" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama"
                        required
                        v-model="currentData.nama"
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Whatsapp"
                        dense
                        v-model="currentData.whatsapp"
                        required
                        :rules="currentData.whatsappRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Email"
                        dense
                        v-model="currentData.email"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan mengirim informasi broadcast ke peserta
              ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogUpload" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Upload Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formUpload"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="currentUpload.file"
                        accept=".xlsx, .xls"
                        prepend-icon="mdi-file"
                        show-size
                        label="Upload file excel"
                        :rules="currentUpload.fileRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogUpload = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSaveUpload">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCopy" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Copy Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formCopy"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        label="Kategori"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.jenis_peserta"
                        :items="items_jenis_peserta"
                        item-text="name"
                        @change="changeCopyJenisPeserta"
                        :rules="currentCopy.jenisPesertaRules"
                      ></v-combobox>
                    </v-col>
                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 1
                      "
                      cols="12"
                    >
                      <v-combobox
                        label="Nama PIB"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.pib"
                        :items="items_pib"
                        item-text="nama"
                        :rules="currentCopy.pibRules"
                        @click="openPibModal"
                      ></v-combobox>

                      <v-combobox
                        label="Jenis"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedKategori"
                        :items="items_kategori"
                        item-text="name"
                        @change="getDataByKategori"
                      ></v-combobox>

                      <v-combobox
                        label="Item"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedItem"
                        :items="items_item"
                        item-text="nama"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 2
                      "
                      cols="12"
                    >
                      <v-combobox
                        label="Cabang Perdatin"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedPropinsi"
                        :items="items_propinsi"
                        item-text="name"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 3
                      "
                      cols="12"
                    >
                      <v-combobox
                        label="Nama Event"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.event"
                        :items="items_event"
                        item-text="nama"
                        :rules="currentCopy.eventRules"
                        @click="openEventModal"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 4
                      "
                      cols="12"
                    >
                      <v-combobox
                        label="Nama Inasia"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.inasia"
                        :items="items_inasia"
                        item-text="nama"
                        :rules="currentCopy.inasiaRules"
                        @click="openInasiaModal"
                      ></v-combobox>

                      <v-combobox
                        label="Jenis"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedKategori"
                        :items="items_kategori"
                        item-text="name"
                        @change="getDataByKategoriInasia"
                      ></v-combobox>

                      <v-combobox
                        label="Item"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedItem"
                        :items="items_item"
                        item-text="nama"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      v-if="
                        currentCopy &&
                        currentCopy.jenis_peserta &&
                        currentCopy.jenis_peserta.id == 5
                      "
                      cols="12"
                    >
                      <v-combobox
                        label="Nama KPPIA"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.kppia"
                        :items="items_kppia"
                        item-text="nama"
                        :rules="currentCopy.kppiaRules"
                        @click="openKppiaModal"
                      ></v-combobox>

                      <v-combobox
                        label="Jenis"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedKategori"
                        :items="items_kategori"
                        item-text="name"
                        @change="getDataByKategoriKppia"
                      ></v-combobox>

                      <v-combobox
                        label="Item"
                        clearable
                        dense
                        outlined
                        v-model="currentCopy.selectedItem"
                        :items="items_item"
                        item-text="nama"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCopy = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSaveCopy">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDeleteAll" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus semua peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteAll"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteAllConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeRemove"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="removeItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status" :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.harga`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="deleteItem(item)">
            mdi-send
          </v-icon>
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="removeItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->

    <PibSelectComponent
      @pilihPib="pilihPib"
      ref="modalPib"
      v-model="dialogPib"
    />

    <InasiaSelectComponent
      @pilihInasia="pilihInasia"
      ref="modalInasia"
      v-model="dialogInasia"
    />

    <EventSelectComponent
      @pilihEvent="pilihEvent"
      ref="modalEvent"
      v-model="dialogEvent"
    />

    <KppiaSelectComponent
      @pilihKppia="pilihKppia"
      ref="modalKppia"
      v-model="dialogKppia"
    />
  </div>
</template>

<script>
import PibBroadcastUserService from "../services/PibBroadcastUserService"
import CommonService from "../services/CommonService"
import PibSelectComponent from "../components/PibSelect.vue"
import InasiaSelectComponent from "../components/InasiaSelect.vue"
import KppiaSelectComponent from "../components/KppiaSelect.vue"
import NakesService from "../services/NakesService"
import InvoicePibService from "../services/InvoicePibService"
import InasiaInvoiceService from "../services/inasia/InasiaInvoiceService"
import EventSelectComponent from "../components/EventSelect.vue"

export default {
  props: {
    id: Number,
    pib_id: Number,
  },
  components: {
    PibSelectComponent,
    EventSelectComponent,
    InasiaSelectComponent,
    KppiaSelectComponent,
  },
  data: () => ({
    isProgressBarShow: false,
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Whatsapp", value: "whatsapp", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogCopy: false,
    dialogPib: false,
    dialogInasia: false,
    dialogKppia: false,
    dialogDeleteAll: false,
    dialogRemove: false,
    dialogUpload: false,

    currentData: {
      pelatihan_id: "",
      id: null,
      nama: "",
      email: "",
      whatsapp: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      whatsappRules: [(v) => !!v || "Whatsapp harus diisi"],
    },

    currentCopy: {
      broadcast_id: "",
      jenis_peserta: "",
      pib: "",
      pib_id: "",
      selectedPropinsi: null,
      selectedKategori: null,
      selectedItem: null,
      event: "",
      event_id: "",
      inasia: "",
      inasia_id: "",
      kppia: "",
      kppia_id: "",
      jenisPesertaRules: [(v) => !!v || "Jenis Peserta harus diisi"],
      pibRules: [(v) => !!v || "Nama PIB harus diisi"],
      inasiaRules: [(v) => !!v || "Nama Inasia harus diisi"],
      kppiaRules: [(v) => !!v || "Nama KPPIA harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      eventRules: [(v) => !!v || "Event harus diisi"],
    },

    currentUpload: {
      broadcast_id: "",
      file: null,
      fileRules: [(v) => !!v || "File harus diisi"],
    },

    items_jenis_peserta: [
      {
        id: 1,
        name: "Peserta PIB",
      },
      {
        id: 2,
        name: "Anggota Perdatin",
      },
      {
        id: 3,
        name: "Event",
      },
      {
        id: 4,
        name: "Peserta INASIA",
      },
      {
        id: 5,
        name: "Peserta KPPIA",
      },
    ],
    items_pib: [],
    items_inasia: [],
    items_kppia: [],
    items_propinsi: [],
    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
      {
        id: 3,
        name: "Paket Wisata",
      },
      {
        id: 4,
        name: "Hotel",
      },
      {
        id: 5,
        name: "Hybrid Simposium",
      },
    ],
    items_item: [],
    items_event: [],
    dialogEvent: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi()
    this.getDataFromApi()
  },
  methods: {
    openEventModal: async function () {
      this.$refs.modalEvent.getDataFromApi()
      this.dialogEvent = true
    },

    pilihEvent(item) {
      this.dialogEvent = false
      console.log(item)
      this.currentCopy.event = item.judul
      this.currentCopy.event_id = item.id

      this.getDataByKategori()
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = []
          this.items_propinsi.push({ id: "", name: "Semua Cabang" })
          this.items_propinsi.push(...res.data.list_data)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getDataByKategori() {
      if (this.currentCopy.selectedKategori)
        this.getKategoriItem(this.currentCopy.selectedKategori.id)
      else this.getKategoriItem("")

      this.currentCopy.selectedItem = null
    },

    getKategoriItem(kategori) {
      console.log(this.currentCopy.pib_id)
      InvoicePibService.getKategoriItemByPib(kategori, this.currentCopy.pib_id)
        .then((res) => {
          this.items_item = res.data.list_data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },

    getDataByKategoriInasia() {
      if (this.currentCopy.selectedKategori)
        this.getKategoriItemInasia(this.currentCopy.selectedKategori.id)
      else this.getKategoriItemInasia("")

      this.currentCopy.selectedItem = null
    },

    getKategoriItemInasia(kategori) {
      InasiaInvoiceService.getKategoriItemByInasia(
        this.currentCopy.inasia_id,
        kategori
      )
        .then((res) => {
          this.items_item = res.data.list_data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },

    getDataByKategoriKppia() {
      if (this.currentCopy.selectedKategori)
        this.getKategoriItemKppia(this.currentCopy.selectedKategori.id)
      else this.getKategoriItemKppia("")

      this.currentCopy.selectedItem = null
    },

    getKategoriItemKppia(kategori) {
      InasiaInvoiceService.getKategoriItemByInasia(
        this.currentCopy.kppia_id,
        kategori
      )
        .then((res) => {
          this.items_item = res.data.list_data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },

    changeCopyJenisPeserta() {},
    openPibModal: async function () {
      this.$refs.modalPib.getDataFromApi()
      this.dialogPib = true
    },

    openInasiaModal: async function () {
      this.$refs.modalInasia.getDataFromApi()
      this.dialogInasia = true
    },

    openKppiaModal: async function () {
      this.$refs.modalKppia.getDataFromApi()
      this.dialogKppia = true
    },

    pilihPib(item) {
      this.dialogPib = false
      console.log(item)
      this.currentCopy.pib = item.nama
      this.currentCopy.pib_id = item.id

      this.getDataByKategori()
    },

    pilihInasia(item) {
      this.dialogInasia = false
      console.log(item)
      this.currentCopy.inasia = item.nama
      this.currentCopy.inasia_id = item.id

      this.getDataByKategoriInasia()
    },

    pilihKppia(item) {
      this.dialogKppia = false
      console.log(item)
      this.currentCopy.kppia = item.nama
      this.currentCopy.kppia_id = item.id

      this.getDataByKategoriKppia()
    },

    getStatus(id) {
      if (id == 1) {
        return "Terkirim"
      } else if (id == 2) {
        return "Tidak Terkirim"
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus)
      switch (dataStatus) {
        case 1:
          return "green"
        case 2:
          return "pink"
      }
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true
      PibBroadcastUserService.pesertaList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        this.pib_id
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    openTambah() {
      this.currentData.id = null
      this.dialog = true
      this.currentData.nama = ""
      this.currentData.email = ""
      this.currentData.whatsapp = ""
      this.isEditable = 1
    },

    openUpload() {
      this.currentUpload.file = null
      this.dialogUpload = true
    },

    downloadSample() {
      window.open("https://api.perdatin.org/image-upload/upload.xlsx", "_blank")
    },

    openCopy() {
      this.dialogCopy = true

      this.currentCopy.jenis_peserta = null
      this.currentCopy.pib = ""
      this.currentCopy.pib_id = ""
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true
      console.log(this.editedItem)
      // this.currentData = this.editedItem;
      this.currentData.id = this.editedItem.id
      this.currentData.nama = this.editedItem.nama
      this.currentData.email = this.editedItem.email
      this.currentData.whatsapp = this.editedItem.whatsapp

      this.isEditable = 1
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.currentData.id == null) {
        this.currentData.broadcast_id = this.id
        PibBroadcastUserService.create(this.currentData)
          .then((res) => {
            console.log(res)
            this.dialog = false

            CommonService.showSuccessToast("Tambah Peserta berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)

            CommonService.showErrorToast("Tambah Peserta gagal. Coba lagi")
          })
      } else {
        this.currentData.broadcast_id = this.id

        PibBroadcastUserService.update(this.currentData)
          .then((res) => {
            this.dialog = false

            console.log(res)

            CommonService.showSuccessToast("Update Peserta berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)

            CommonService.showErrorToast("Update Peserta gagal. Coba lagi")
          })
      }
    },

    doSaveUpload() {
      let loader = this.$loading.show()

      if (!this.$refs.formUpload.validate()) {
        return
      }

      this.currentUpload.broadcast_id = this.id
      PibBroadcastUserService.uploadPeserta(this.currentUpload)
        .then((res) => {
          loader.hide()
          console.log(res)
          this.dialogUpload = false

          CommonService.showSuccessToast("Upload Peserta berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          loader.hide()
          console.log(err)

          CommonService.showErrorToast("Upload Peserta gagal. Coba lagi")
        })
    },

    doSaveCopy() {
      if (!this.$refs.formCopy.validate()) {
        return
      }

      this.isProgressBarShow = true

      this.currentCopy.broadcast_id = this.id
      PibBroadcastUserService.copyPeserta(this.currentCopy)
        .then((res) => {
          this.isProgressBarShow = false
          console.log(res)
          this.dialogCopy = false

          CommonService.showSuccessToast("Copy Peserta berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          this.isProgressBarShow = false
          console.log(err)

          CommonService.showErrorToast("Copy Peserta gagal. Coba lagi")
        })
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    removeItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogRemove = true
    },

    deleteItemConfirm() {
      this.closeDelete()
      this.isProgressBarShow = true

      PibBroadcastUserService.send(this.editedItem.id, this.id)
        .then((res) => {
          this.isProgressBarShow = false
          console.log(res)
          CommonService.showSuccessToast("Kirim Informasi Broadcast berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          this.isProgressBarShow = false
          console.log(err)
          CommonService.showErrorToast(
            "Kirim Informasi Broadcast gagal. Coba lagi"
          )
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    openDeleteAll() {
      this.dialogDeleteAll = true
    },

    closeDeleteAll() {
      this.dialogDeleteAll = false
    },

    deleteAllConfirm() {
      this.closeDeleteAll()
      this.isProgressBarShow = true

      PibBroadcastUserService.deleteAllPeserta(this.id)
        .then((res) => {
          this.isProgressBarShow = false
          console.log(res)
          CommonService.showSuccessToast("Hapus Peserta berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          this.isProgressBarShow = false
          console.log(err)
          CommonService.showErrorToast("Hapus Peserta gagal. Coba lagi")
        })
    },

    closeRemove() {
      this.dialogRemove = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    removeItemConfirm() {
      this.closeRemove()
      this.isProgressBarShow = true

      PibBroadcastUserService.remove(this.editedItem.id)
        .then((res) => {
          this.isProgressBarShow = false
          console.log(res)
          CommonService.showSuccessToast("Hapus Peserta berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          this.isProgressBarShow = false
          console.log(err)
          CommonService.showErrorToast("Hapus Peserta gagal. Coba lagi")
        })
    },
  },
}
</script>
